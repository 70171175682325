<template>
  <div class="box">
    <van-cell-group>
      <van-cell title="公司名称" :value="info.companyName" />
      <van-cell title="法定代表人" :value="info.corporation" />
      <van-cell title="统一社会信用代码" :value="info.taxAccount" />
      <van-cell title="成立时间" :value="info.openingDate" />
      <van-cell title="纳税评级" :value="info.taxLevel" />
    </van-cell-group>
    <div class="bottom">
      <div class="tag-box" @click="toInfo(1)">
        <div class="left">
          <div class="left-title">了解企业概况</div>
          <div class="left-hint">企业详情</div>
        </div>
        <img src="@/img/company/tag1.png" class="right" />
      </div>
      <div class="tag-box" @click="toInfo(2)">
        <div class="left">
          <div class="left-title">测额记录</div>
          <div class="left-hint">可一键查询企业测额记录</div>
        </div>
        <img src="@/img/company/tag2.png" class="right" />
      </div>
      <div class="tag-box" @click="toInfo(3)">
        <div class="left">
          <div class="left-title">融资方案</div>
          <div class="left-hint">精准匹配适合产品</div>
        </div>
        <img src="@/img/company/tag3.png" class="right" />
      </div>
      <div class="tag-box" @click="toInfo(4)">
        <div class="left">
          <div class="left-title">融资报告</div>
          <div class="left-hint">准入原因可查，帮助企业完善自身不足</div>
        </div>
        <img src="@/img/company/tag4.png" class="right" />
      </div>
    </div>
  </div>
</template>
<script>
import url from "@/service/url-config";
import { Toast } from "vant";
export default {
  data() {
    return {
      info: {},
      userInfo: {},
    };
  },
  created() {
    this.info = JSON.parse(this.$route.query.info);
    this.$http.get(url.userInfo).then((res) => {
      this.userInfo = res.data;
    });
  },
  methods: {
    toInfo(status) {
      if (status == 1) {
        this.$router.push({
          path: "company",
          query: { companyId: this.info.companyId },
        });
      } else if (status == 2) {
        this.$router.push({
          path: "matchRecord",
          query: { companyId: this.info.companyId },
        });
      } else if (status == 3) {
        this.$router.push({
          path: "pipeiChanPin",
          query: { loanPredictionId: this.info.loanPredictionId },
        });
      } else if (status == 4) {
        if (this.userInfo.vip == 0) {
          Toast("请开通VIP");
        } else {
          this.$router.push({
            path: "report",
            query: {
              companyId: this.info.companyId,
              loanPredictionId: this.info.loanPredictionId,
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  padding-top: 80px;
  background: #f4f5f9;
  min-height: calc(100vh - 80px);
  .bottom {
    width: 360px;
    margin: 0 auto;
    margin-top: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .tag-box {
      width: 175px;
      height: 78px;
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 5px;
      .left {
        width: 100px;
        margin-left: 17px;
        .left-title {
          color: #000000;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .left-hint {
          color: #999999;
          font-size: 11px;
        }
      }
      .right {
        width: 50px;
        height: 50px;
      }
    }
  }
}
</style>
